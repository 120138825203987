<template>
  <div class="col-md-12" style="margin-top: 3%; margin-bottom: 3%">
    <div class="card card-container">
      <!-- NMN Logo animated -->
      <div class="animlogo">
        <LogoSVG></LogoSVG>
      </div>
      <div class="profile-img-card" id="profile-img"></div>

      <!-- Form -->
      <Form @submit="handleLogin" :validation-schema="schema">
        <!-- Input username -->
        <div class="form-group">
          <label for="username" style="color: white; margin-bottom: 5px">
            Brukernavn
          </label>
          <Field
            name="username"
            type="text"
            class="form-control"
            placeholder="..."
            :validateOnBlur="false"
            :validateOnChange="false"
            :validateOnInput="false"
            required
          />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <!-- Input password -->
        <div class="form-group" style="margin-top: 16px">
          <label for="password" style="color: white; margin-bottom: 5px">
            Passord
          </label>
          <Field
            name="password"
            type="password"
            class="form-control"
            placeholder="..."
            :validateOnBlur="false"
            :validateOnChange="false"
            :validateOnInput="false"
            required
          />
          <ErrorMessage name="password" class="error-feedback" />
        </div>
        <!-- Remember me switch - not implemented -->
         <!--<div class="form-check form-switch" style="margin-top: 16px">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
          />
          <label
            class="form-check-label"
            for="flexSwitchCheckDefault"
            style="color: white"
          >
            Husk meg
          </label>
        </div>  -->
        <br />
        <!-- Login button -->
        <div class="form-group" style="margin-top: 16px">
          <b-button
            type="submit"
            class="btn btn-primary btn-block w-100"
            variant="success"
            :disabled="loading"
            :pressed="false"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Login</span>
          </b-button>
        </div>

        <div class="registrer" style="margin-top: 16px">
          <router-link to="/registrerdeg">Ny? Registrer deg her </router-link>
        </div>

        <div class="registrer" style="margin-top: 16px">
          <router-link to="/innlogging/glemtpassord">Glemt passordet? </router-link>
        </div>

        <use-conditions-modal></use-conditions-modal>

        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import LogoSVG from "../../components/LogoSVG.vue";
import UseConditionsModal from "../../components/UseConditionsModal.vue";
export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
    LogoSVG,
    UseConditionsModal,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Brukernavn mangler!"),
      password: yup.string().required("Passord mangler!"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/brukerprofil");
        },
        (error) => {
          console.log("error");
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<!-- Styling -->
<style scoped>
/* Form container */
.card-container.card {
  max-width: 500px !important;
  padding: 30px 30px;
}

/* Form styling */
.card {
  background-color: #212529;
  padding: 16px;
  margin: 0 auto;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

/* Input focus white glow */
.form-control:focus {
  border-color: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px white;
}

/* Logo */
.profile-img-card {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: -30px;
  margin-bottom: -100px;
}

/* Animated logo */
.animlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

/* Error message */
.error-feedback {
  color: red;
}

/* Register link styling */
.registrer a {
  color: white;
}

.registrer a:active {
  color: #9b9d9e;
}

/* Remember me switch */
.form-switch .form-check-input {
  height: 20px;
  width: 38px;
  margin-right: 10px;
}
.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: #198754;
  border-color: #198754;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}
</style>
