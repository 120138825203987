<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1920 1080"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g transform="matrix(0.72757,0,0,0.72757,31.736,62.4345)">
      <g>
        <g>
          <g>
            <path
              d="M946.85,462.42C966.44,416.62 1082.43,145.58 1093.65,123.86C1103.65,104.49 1119.22,90.96 1131.51,90.96C1133.86,90.96 1136.03,91.47 1137.96,92.46C1150.22,98.79 1150.23,119.75 1138,143.42C1126.78,165.16 972.77,419.61 946.85,462.42Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_0"
            ></path>
            <path
              d="M1131.52,90.45L1131.52,91.45C1133.79,91.45 1135.88,91.94 1137.74,92.9C1149.73,99.1 1149.66,119.77 1137.57,143.19C1126.8,164.05 984.38,399.47 950.11,456.07C976.01,395.53 1083.33,144.93 1094.1,124.08C1104.02,104.87 1119.41,91.45 1131.52,91.45L1131.52,90.45M1131.52,90.45C1119.32,90.45 1103.54,103.62 1093.22,123.62C1080.87,147.53 943.61,468.75 943.61,468.75C943.61,468.75 1126.12,167.56 1138.47,143.65C1150.82,119.74 1150.7,98.47 1138.21,92.01C1136.15,90.96 1133.9,90.45 1131.52,90.45Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_1"
            ></path>
          </g>
          <g>
            <path
              d="M1247.65,731.42C1240.12,731.42 1232.25,729.31 1224.91,725.32C1208.01,716.13 1014.81,584.97 983.83,563.91C1018.35,578.46 1233.5,669.24 1250.39,678.42C1268.64,688.34 1277.77,706.92 1270.74,719.85C1266.76,727.21 1258.34,731.42 1247.65,731.42Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_2"
            ></path>
            <path
              d="M988.29,566.33C1034.07,585.64 1233.92,670.03 1250.16,678.86C1268.17,688.65 1277.21,706.93 1270.31,719.61C1266.41,726.79 1258.15,730.91 1247.65,730.91C1240.2,730.91 1232.42,728.82 1225.15,724.87C1208.91,716.05 1029.39,594.25 988.29,566.33M979.39,561.5C979.39,561.5 1206.15,715.69 1224.67,725.76C1232.31,729.91 1240.34,731.92 1247.65,731.92C1258.05,731.92 1266.97,727.85 1271.19,720.1C1278.36,706.91 1269.16,688.06 1250.64,677.99C1232.12,667.92 979.39,561.5 979.39,561.5Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_3"
            ></path>
          </g>
          <g>
            <path
              d="M1108.19,890.72C1100.69,890.72 1090.96,883.75 1083.98,873.39C1075.75,861.16 989.45,709.98 971.3,678.16C993.95,706.95 1101.55,843.79 1109.78,856.02C1118.89,869.55 1120.51,884.45 1113.4,889.23C1111.94,890.22 1110.19,890.72 1108.19,890.72Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_4"
            ></path>
            <path
              d="M975.08,683.77C1004.41,721.07 1101.57,844.72 1109.37,856.31C1113.77,862.84 1116.54,869.87 1117.18,876.12C1117.8,882.14 1116.36,886.65 1113.12,888.83C1111.74,889.76 1110.08,890.23 1108.19,890.23C1100.85,890.23 1091.29,883.35 1084.4,873.12C1076.6,861.53 998.6,724.98 975.08,683.77M967.54,672.57C967.54,672.57 1074.3,859.89 1083.57,873.67C1090.85,884.49 1100.73,891.22 1108.19,891.22C1110.23,891.22 1112.1,890.72 1113.68,889.65C1121.03,884.7 1119.47,869.52 1110.2,855.74C1100.92,841.97 967.54,672.57 967.54,672.57Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_5"
            ></path>
          </g>
          <g>
            <path
              d="M787.65,989.05C786.65,989.05 785.68,988.91 784.75,988.62C780.19,987.23 776.88,982.61 775.44,975.6C773.98,968.48 774.62,959.79 777.26,951.13C782.18,934.95 852.26,747.38 865.96,710.76C857.94,749.08 816.76,945.47 811.85,961.64C807.09,977.26 796.69,989.05 787.65,989.05Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_6"
            ></path>
            <path
              d="M864.11,717.11C853.63,767.13 816.04,946.1 811.36,961.49C806.67,976.91 796.48,988.54 787.65,988.54C786.7,988.54 785.77,988.4 784.89,988.14C775.62,985.32 772.41,968.78 777.73,951.27C782.41,935.87 846.24,764.92 864.11,717.11M867.79,704.42C867.79,704.42 782.27,932.91 776.78,950.98C771.29,969.05 774.79,986.11 784.61,989.1C785.6,989.4 786.62,989.55 787.66,989.55C796.86,989.55 807.39,978.03 812.32,961.79C817.81,943.72 867.79,704.42 867.79,704.42Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_7"
            ></path>
          </g>
          <g>
            <path
              d="M829.43,503.29C806.34,485.21 687.7,392.15 675.78,380.17C662.67,367 657.76,350.57 664.82,343.54C666.91,341.46 669.95,340.36 673.61,340.36C681.97,340.36 692.62,345.84 701.4,354.66C713.32,366.66 810.5,481 829.43,503.29Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_8"
            ></path>
            <path
              d="M673.61,340.87C681.84,340.87 692.35,346.3 701.05,355.03C712.4,366.43 801.13,470.74 825.86,499.86C795.71,476.22 687.48,391.22 676.14,379.83C669.81,373.47 665.21,366.18 663.19,359.28C661.23,352.6 661.93,347.14 665.18,343.91C667.19,341.89 670.03,340.87 673.61,340.87M673.61,339.87C669.91,339.87 666.73,340.94 664.47,343.19C657.2,350.43 662.11,367.14 675.43,380.53C688.75,393.91 832.99,506.72 832.99,506.72C832.99,506.72 715.07,367.71 701.75,354.32C692.58,345.1 681.8,339.87 673.61,339.87Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_9"
            ></path>
          </g>
          <g>
            <path
              d="M857.4,438.83C844.25,413.42 782.34,293.57 776.61,278.39C773.51,270.17 772.11,261.88 772.66,255.05C773.21,248.35 775.56,243.88 779.29,242.47C780.11,242.16 780.98,242.01 781.9,242.01C789.7,242.01 799.43,252.8 805.04,267.66C810.77,282.84 849.23,411.49 857.4,438.83Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_10"
            ></path>
            <path
              d="M781.9,242.5C789.38,242.5 799.12,253.39 804.57,267.83C810,282.21 844.81,398.46 855.39,433.87C838.37,400.96 782.5,292.58 777.07,278.21C770.91,261.9 772.01,245.75 779.46,242.93C780.23,242.65 781.05,242.5 781.9,242.5M781.9,241.5C780.94,241.5 780,241.66 779.12,242C771.01,245.06 769.68,261.43 776.14,278.57C782.6,295.71 859.39,443.78 859.39,443.78C859.39,443.78 811.97,284.62 805.51,267.49C799.75,252.22 789.75,241.5 781.9,241.5Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_11"
            ></path>
          </g>
          <g>
            <path
              d="M1054.37,482.38C1091.7,452.55 1307.9,279.89 1326.7,266.99C1338.01,259.23 1349.36,254.95 1358.68,254.95C1365.9,254.95 1371.48,257.53 1374.83,262.41C1378.62,267.93 1378.39,275 1374.16,282.84C1370.05,290.47 1362.2,298.5 1352.06,305.46C1333.27,318.37 1095.5,458.21 1054.37,482.38Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_12"
            ></path>
            <path
              d="M1358.69,254.46L1358.69,255.46C1365.73,255.46 1371.18,257.97 1374.43,262.71C1381.91,273.61 1372.81,290.63 1351.79,305.07C1333.78,317.43 1114.42,446.52 1060.16,478.42C1109.42,439.07 1308.98,279.78 1326.99,267.42C1338.21,259.72 1349.47,255.47 1358.69,255.47L1358.69,254.46M1358.69,254.46C1349.49,254.46 1338.07,258.59 1326.43,266.58C1305.7,280.81 1048.62,486.35 1048.62,486.35C1048.62,486.35 1331.63,320.11 1352.36,305.88C1373.09,291.65 1383.34,273.91 1375.26,262.13C1371.71,256.97 1365.87,254.46 1358.69,254.46Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_13"
            ></path>
          </g>
          <g>
            <path
              d="M959.73,493.84C972.84,478.06 1030.92,408.28 1040.42,399.48C1047.15,393.25 1054.32,389.67 1060.09,389.67C1063.22,389.67 1065.76,390.7 1067.65,392.73C1072.95,398.45 1069.12,409.14 1058.11,419.33C1048.61,428.13 976.17,481.71 959.73,493.84Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_14"
            ></path>
            <path
              d="M1060.09,390.18C1063.08,390.18 1065.5,391.16 1067.28,393.08C1070.41,396.46 1069.66,401.02 1068.48,404.26C1066.78,408.93 1062.98,414.15 1057.77,418.97C1048.83,427.25 983.89,475.37 962.75,490.99C979.63,470.69 1031.82,408.13 1040.76,399.85C1047.4,393.71 1054.44,390.18 1060.09,390.18M1060.09,389.18C1054.31,389.18 1047.06,392.66 1040.08,399.12C1029.27,409.13 956.73,496.68 956.73,496.68C956.73,496.68 1047.64,429.71 1058.45,419.7C1069.26,409.69 1073.7,398.54 1068.01,392.4C1065.99,390.23 1063.26,389.18 1060.09,389.18Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_15"
            ></path>
          </g>
          <g>
            <path
              d="M775.75,853.26C774.43,853.26 773.16,852.98 771.95,852.44C768.45,850.86 766.46,847.31 766.2,842.18C765.95,837.14 767.43,830.77 770.38,824.23C775.71,812.44 821.6,734.89 832.02,717.32C826.09,736.95 799.72,823.84 794.4,835.63C789.5,846.51 782.35,853.26 775.75,853.26Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_16"
            ></path>
            <path
              d="M830.36,721.12C822.71,746.38 798.97,824.32 793.95,835.42C789.12,846.11 782.15,852.75 775.75,852.75C774.51,852.75 773.3,852.49 772.16,851.97C767.97,850.08 766.88,845.58 766.71,842.14C766.46,837.18 767.93,830.89 770.85,824.42C775.86,813.34 816.96,743.73 830.36,721.12M833.7,713.54C833.7,713.54 776,810.6 769.94,824.03C763.88,837.46 764.13,849.46 771.75,852.9C773.04,853.48 774.39,853.77 775.76,853.77C782.48,853.77 789.83,847 794.87,835.85C800.93,822.41 833.7,713.54 833.7,713.54Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_17"
            ></path>
          </g>
          <g>
            <path
              d="M681.02,733.11C677.88,733.11 675.34,732.08 673.46,730.04C668.17,724.31 672,713.63 683.01,703.45C692.51,694.67 745.39,660.2 757.31,652.47C748.7,663.85 710.19,714.52 700.69,723.31C693.95,729.54 686.79,733.11 681.02,733.11Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_18"
            ></path>
            <path
              d="M755.18,654.45C744.06,669.11 709.28,714.68 700.34,722.94C693.7,729.08 686.65,732.6 681.02,732.6C678.03,732.6 675.61,731.62 673.82,729.69C668.73,724.18 672.56,713.78 683.34,703.81C692.28,695.56 739.83,664.44 755.18,654.45M759.42,650.52C759.42,650.52 693.49,693.09 682.67,703.09C671.85,713.09 667.41,724.24 673.09,730.39C675.1,732.57 677.84,733.62 681.02,733.62C686.8,733.62 694.04,730.15 701.02,723.69C711.84,713.68 759.42,650.52 759.42,650.52Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_19"
            ></path>
          </g>
          <g>
            <path
              d="M562.69,614.86C551.76,614.86 544.1,609.89 542.69,601.89C541.86,597.16 543.53,592.72 547.54,589.05C551.45,585.48 557.47,582.83 564.5,581.59C568.32,580.92 577.08,580.58 590.53,580.58C614.49,580.58 646.65,581.68 655.43,581.99C643.28,587.02 582.61,611.95 569.74,614.22C567.33,614.64 564.96,614.86 562.69,614.86Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_20"
            ></path>
            <path
              d="M590.54,581.07C612.73,581.07 641.96,582.01 653.12,582.4C637.26,588.94 581.86,611.57 569.66,613.72C567.27,614.14 564.93,614.35 562.69,614.35C552.02,614.35 544.55,609.54 543.18,601.8C541.55,592.55 550.15,584.62 564.59,582.08C568.39,581.41 577.11,581.07 590.54,581.07M590.54,580.07C578.67,580.07 568.72,580.34 564.43,581.09C549.96,583.64 540.42,591.8 542.21,601.97C543.7,610.4 551.75,615.35 562.7,615.35C564.97,615.35 567.36,615.14 569.84,614.7C584.31,612.15 657.75,581.56 657.75,581.56C657.75,581.56 618.72,580.07 590.54,580.07Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_21"
            ></path>
          </g>
          <g>
            <path
              d="M725.84,595.73C717.52,595.73 711.57,591.28 710.31,584.13C709.59,580.04 710.7,576.27 713.53,573.23C716.27,570.28 720.57,568.16 725.63,567.27C727.29,566.98 730.3,566.83 734.57,566.83C751.42,566.83 783.57,569.15 791.73,569.76C782.49,574.02 739.45,593.71 730.2,595.34C728.72,595.6 727.25,595.73 725.84,595.73Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_22"
            ></path>
            <path
              d="M734.57,566.33L734.57,567.33C750.28,567.33 779.33,569.35 789.76,570.11C777.79,575.61 738.82,593.31 730.12,594.85C728.67,595.11 727.23,595.24 725.84,595.24C717.78,595.24 712.02,590.95 710.8,584.05C709.4,576.13 715.4,569.58 725.71,567.76C727.34,567.47 730.32,567.33 734.56,567.33L734.57,566.33M734.57,566.33C730.53,566.33 727.35,566.46 725.55,566.78C715.04,568.63 708.27,575.43 709.82,584.22C711.14,591.73 717.48,596.23 725.84,596.23C727.27,596.23 728.76,596.1 730.29,595.83C740.8,593.98 793.69,569.4 793.69,569.4C793.69,569.4 754.11,566.33 734.57,566.33Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_23"
            ></path>
          </g>
          <g>
            <path
              d="M804.98,656.57C801.14,656.57 797.71,655.03 795.05,652.11C789.44,645.94 791.02,636.92 798.99,629.67C805.94,623.35 854.18,594.78 864.68,588.6C857.6,598.58 824.81,644.63 817.86,650.95C813.87,654.57 809.3,656.57 804.98,656.57Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_24"
            ></path>
            <path
              d="M862.91,590.21C853.74,603.11 824.06,644.62 817.52,650.57C813.63,654.11 809.17,656.06 804.98,656.06C801.29,656.06 797.98,654.57 795.42,651.76C790.01,645.81 791.57,637.08 799.32,630.03C805.86,624.08 849.35,598.22 862.91,590.21M866.43,586.98C866.43,586.98 806.54,622.12 798.65,629.3C790.76,636.48 788.67,645.84 794.68,652.45C797.5,655.55 801.12,657.08 804.98,657.08C809.34,657.08 814.01,655.14 818.19,651.33C826.08,644.13 866.43,586.98 866.43,586.98Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_25"
            ></path>
          </g>
          <g>
            <path
              d="M902.65,501.24C897.36,491.22 872.9,444.67 870.71,435.54C868.29,425.44 872.39,416.99 880.68,415C881.73,414.75 882.79,414.62 883.82,414.62C890.68,414.62 896.28,420.23 898.45,429.26C900.65,438.39 902.32,490.02 902.65,501.24Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_26"
            ></path>
            <path
              d="M883.83,414.12L883.83,415.12C890.44,415.12 895.86,420.58 897.97,429.38C900.03,437.98 901.63,484.59 902.09,499.1C895.26,486.13 873.27,444.02 871.21,435.43C870.06,430.62 870.38,426 872.12,422.42C873.86,418.84 876.87,416.44 880.82,415.49C881.84,415.25 882.85,415.12 883.84,415.12L883.83,414.12M883.83,414.12C882.77,414.12 881.69,414.25 880.58,414.52C871.89,416.6 867.75,425.29 870.24,435.66C872.73,446.03 903.22,503.38 903.22,503.38C903.22,503.38 901.44,439.53 898.95,429.15C896.77,420.1 891.03,414.12 883.83,414.12Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_27"
            ></path>
          </g>
          <g>
            <path
              d="M925.65,658.89C917.93,658.89 912.34,652.33 911.4,642.17C910.54,632.83 918.41,606.92 920.1,601.5C923.32,606.41 938.89,630.57 939.75,639.92C940.71,650.27 935.44,658.04 926.95,658.83C926.51,658.87 926.07,658.89 925.65,658.89Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_28"
            ></path>
            <path
              d="M920.26,602.67C924.42,609.08 938.44,631.2 939.25,639.97C939.7,644.89 938.73,649.42 936.49,652.72C934.25,656.02 930.94,657.96 926.89,658.33C926.47,658.37 926.05,658.39 925.63,658.39C918.19,658.39 912.8,652.01 911.88,642.13C911.09,633.37 918.09,609.72 920.26,602.67M919.94,600.35C919.94,600.35 909.92,631.59 910.9,642.22C911.83,652.3 917.47,659.39 925.64,659.39C926.08,659.39 926.53,659.37 926.99,659.33C935.88,658.51 941.23,650.5 940.25,639.88C939.27,629.25 919.94,600.35 919.94,600.35Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_29"
            ></path>
          </g>
          <g>
            <path
              d="M1023.8,656.95C1019.03,656.95 1014.05,654.36 1009.78,649.67C1003.47,642.72 982.59,606.21 978.08,598.28C986.28,602.74 1024.75,623.86 1031.07,630.81C1038.06,638.5 1038.53,647.88 1032.22,653.62C1029.82,655.8 1026.91,656.95 1023.8,656.95Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_30"
            ></path>
            <path
              d="M979.39,599.56C990.01,605.36 1024.77,624.62 1030.7,631.14C1037.6,638.73 1038.08,647.61 1031.88,653.24C1029.54,655.37 1026.82,656.45 1023.79,656.45C1019.17,656.45 1014.32,653.92 1010.14,649.33C1004.21,642.8 985.24,609.82 979.39,599.56M976.79,597.01C976.79,597.01 1002.24,642.11 1009.41,650.01C1013.77,654.8 1018.93,657.46 1023.8,657.46C1026.95,657.46 1029.96,656.35 1032.56,653.99C1039.17,647.98 1038.62,638.37 1031.44,630.48C1024.27,622.58 976.79,597.01 976.79,597.01Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_31"
            ></path>
          </g>
          <g>
            <path
              d="M1053.36,534.23C1042.91,534.23 1031.55,533.36 1028.07,533.07C1032.3,528.89 1052.95,508.78 1061.8,505.63C1064.45,504.68 1067.05,504.21 1069.55,504.21C1076.11,504.21 1081.25,507.63 1083.3,513.35C1086.1,521.21 1081.17,528.92 1071.02,532.54C1067.86,533.65 1061.92,534.23 1053.36,534.23Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_32"
            ></path>
            <path
              d="M1069.54,503.7L1069.54,504.7C1075.98,504.7 1080.82,507.91 1082.81,513.51C1085.51,521.09 1080.7,528.54 1070.84,532.06C1067.74,533.17 1061.86,533.73 1053.35,533.73C1043.83,533.73 1033.56,533 1029.19,532.66C1034.72,527.24 1053.66,509.06 1061.95,506.1C1064.54,505.17 1067.09,504.7 1069.53,504.7L1069.54,503.7M1069.54,503.7C1067.04,503.7 1064.37,504.17 1061.63,505.15C1051.58,508.74 1026.95,533.47 1026.95,533.47C1026.95,533.47 1040.72,534.73 1053.36,534.73C1060.63,534.73 1067.51,534.31 1071.18,533C1081.23,529.41 1086.76,521.58 1083.76,513.17C1081.58,507.05 1076.19,503.7 1069.54,503.7Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_33"
            ></path>
          </g>
          <g>
            <path
              d="M874.99,544.47C869.68,544.47 865.42,543.77 862.34,542.4C857.65,540.31 854.19,537.24 852.33,533.53C850.48,529.83 850.4,525.84 852.12,521.99C854.32,517.06 858.75,514.34 864.59,514.34C867.63,514.34 870.97,515.11 874.25,516.57C882.87,520.41 888.32,539.71 889.22,543.11C887.45,543.44 881.39,544.47 874.99,544.47Z"
              style="
                fill: none;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_34"
            ></path>
            <path
              d="M864.59,513.84L864.59,514.84C867.56,514.84 870.83,515.6 874.05,517.03C882.1,520.62 887.38,538.26 888.6,542.71C886.36,543.11 880.81,543.97 874.99,543.97C869.75,543.97 865.56,543.29 862.54,541.95C858.02,539.94 854.55,536.87 852.77,533.31C850.99,529.75 850.92,525.9 852.57,522.19C854.68,517.45 858.95,514.84 864.58,514.84L864.59,513.84M864.59,513.84C858.84,513.84 854.03,516.48 851.66,521.78C848.03,529.94 852.39,538.52 862.14,542.86C865.74,544.46 870.47,544.97 874.99,544.97C882.7,544.97 889.84,543.5 889.84,543.5C889.84,543.5 884.2,520.46 874.45,516.12C871.05,514.6 867.68,513.84 864.59,513.84Z"
              style="
                fill: white;
                fill-rule: nonzero;
                stroke: white;
                stroke-width: 4.12px;
              "
              class="RiOWnJNP_35"
            ></path>
          </g>
        </g>
      </g>
    </g>
    <g transform="matrix(1,0,0,1,439.721,-440.663)">
      <text
        x="488.736px"
        y="886.975px"
        style="
          font-family: 'Tahoma-Bold', 'Tahoma', sans-serif;
          font-weight: 700;
          font-size: 96px;
          fill: white;
        "
      >
        NORSK
      </text>
      <text
        x="488.736px"
        y="958.975px"
        style="font-family: 'Tahoma', sans-serif; font-size: 72px; fill: white"
      >
        METEORNETTVERK
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoSVG',
}
</script>

<style scoped>
.RiOWnJNP_0 {
  stroke-dasharray: 856 858;
  stroke-dashoffset: 857;
  animation: RiOWnJNP_draw_0 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_1 {
  stroke-dasharray: 1714 1716;
  stroke-dashoffset: 1715;
  animation: RiOWnJNP_draw_1 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_2 {
  stroke-dasharray: 683 685;
  stroke-dashoffset: 684;
  animation: RiOWnJNP_draw_2 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_3 {
  stroke-dasharray: 1365 1367;
  stroke-dashoffset: 1366;
  animation: RiOWnJNP_draw_3 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_4 {
  stroke-dasharray: 524 526;
  stroke-dashoffset: 525;
  animation: RiOWnJNP_draw_4 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_5 {
  stroke-dasharray: 1047 1049;
  stroke-dashoffset: 1048;
  animation: RiOWnJNP_draw_5 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_6 {
  stroke-dasharray: 597 599;
  stroke-dashoffset: 598;
  animation: RiOWnJNP_draw_6 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_7 {
  stroke-dasharray: 1193 1195;
  stroke-dashoffset: 1194;
  animation: RiOWnJNP_draw_7 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_8 {
  stroke-dasharray: 477 479;
  stroke-dashoffset: 478;
  animation: RiOWnJNP_draw_8 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_9 {
  stroke-dasharray: 953 955;
  stroke-dashoffset: 954;
  animation: RiOWnJNP_draw_9 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_10 {
  stroke-dasharray: 437 439;
  stroke-dashoffset: 438;
  animation: RiOWnJNP_draw_10 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_11 {
  stroke-dasharray: 873 875;
  stroke-dashoffset: 874;
  animation: RiOWnJNP_draw_11 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_12 {
  stroke-dasharray: 801 803;
  stroke-dashoffset: 802;
  animation: RiOWnJNP_draw_12 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_13 {
  stroke-dasharray: 1603 1605;
  stroke-dashoffset: 1604;
  animation: RiOWnJNP_draw_13 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_14 {
  stroke-dasharray: 310 312;
  stroke-dashoffset: 311;
  animation: RiOWnJNP_draw_14 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_15 {
  stroke-dasharray: 619 621;
  stroke-dashoffset: 620;
  animation: RiOWnJNP_draw_15 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_16 {
  stroke-dasharray: 310 312;
  stroke-dashoffset: 311;
  animation: RiOWnJNP_draw_16 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_17 {
  stroke-dasharray: 619 621;
  stroke-dashoffset: 620;
  animation: RiOWnJNP_draw_17 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_18 {
  stroke-dasharray: 243 245;
  stroke-dashoffset: 244;
  animation: RiOWnJNP_draw_18 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_19 {
  stroke-dasharray: 486 488;
  stroke-dashoffset: 487;
  animation: RiOWnJNP_draw_19 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_20 {
  stroke-dasharray: 249 251;
  stroke-dashoffset: 250;
  animation: RiOWnJNP_draw_20 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_21 {
  stroke-dasharray: 498 500;
  stroke-dashoffset: 499;
  animation: RiOWnJNP_draw_21 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_22 {
  stroke-dasharray: 185 187;
  stroke-dashoffset: 186;
  animation: RiOWnJNP_draw_22 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_23 {
  stroke-dasharray: 370 372;
  stroke-dashoffset: 371;
  animation: RiOWnJNP_draw_23 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_24 {
  stroke-dasharray: 207 209;
  stroke-dashoffset: 208;
  animation: RiOWnJNP_draw_24 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_25 {
  stroke-dasharray: 414 416;
  stroke-dashoffset: 415;
  animation: RiOWnJNP_draw_25 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_26 {
  stroke-dasharray: 197 199;
  stroke-dashoffset: 198;
  animation: RiOWnJNP_draw_26 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_27 {
  stroke-dasharray: 395 397;
  stroke-dashoffset: 396;
  animation: RiOWnJNP_draw_27 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_28 {
  stroke-dasharray: 137 139;
  stroke-dashoffset: 138;
  animation: RiOWnJNP_draw_28 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_29 {
  stroke-dasharray: 273 275;
  stroke-dashoffset: 274;
  animation: RiOWnJNP_draw_29 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_30 {
  stroke-dasharray: 174 176;
  stroke-dashoffset: 175;
  animation: RiOWnJNP_draw_30 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_31 {
  stroke-dasharray: 348 350;
  stroke-dashoffset: 349;
  animation: RiOWnJNP_draw_31 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_32 {
  stroke-dasharray: 138 140;
  stroke-dashoffset: 139;
  animation: RiOWnJNP_draw_32 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_33 {
  stroke-dasharray: 278 280;
  stroke-dashoffset: 279;
  animation: RiOWnJNP_draw_33 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_34 {
  stroke-dasharray: 110 112;
  stroke-dashoffset: 111;
  animation: RiOWnJNP_draw_34 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
.RiOWnJNP_35 {
  stroke-dasharray: 222 224;
  stroke-dashoffset: 223;
  animation: RiOWnJNP_draw_35 7200ms ease-in-out 0ms infinite,
    RiOWnJNP_fade 7200ms linear 0ms infinite;
}
@keyframes RiOWnJNP_draw {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_fade {
  0% {
    stroke-opacity: 1;
  }
  94.44444444444444% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
@keyframes RiOWnJNP_draw_0 {
  11.11111111111111% {
    stroke-dashoffset: 857;
  }
  38.88888888888889% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_1 {
  11.507936507936508% {
    stroke-dashoffset: 1715;
  }
  39.285714285714285% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_2 {
  11.904761904761903% {
    stroke-dashoffset: 684;
  }
  39.682539682539684% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_3 {
  12.301587301587302% {
    stroke-dashoffset: 1366;
  }
  40.07936507936508% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_4 {
  12.698412698412698% {
    stroke-dashoffset: 525;
  }
  40.476190476190474% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_5 {
  13.095238095238097% {
    stroke-dashoffset: 1048;
  }
  40.87301587301588% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_6 {
  13.492063492063492% {
    stroke-dashoffset: 598;
  }
  41.26984126984127% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_7 {
  13.88888888888889% {
    stroke-dashoffset: 1194;
  }
  41.66666666666667% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_8 {
  14.285714285714288% {
    stroke-dashoffset: 478;
  }
  42.06349206349206% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_9 {
  14.682539682539684% {
    stroke-dashoffset: 954;
  }
  42.46031746031746% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_10 {
  15.07936507936508% {
    stroke-dashoffset: 438;
  }
  42.85714285714286% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_11 {
  15.476190476190474% {
    stroke-dashoffset: 874;
  }
  43.25396825396825% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_12 {
  15.873015873015872% {
    stroke-dashoffset: 802;
  }
  43.65079365079366% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_13 {
  16.26984126984127% {
    stroke-dashoffset: 1604;
  }
  44.04761904761905% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_14 {
  16.666666666666664% {
    stroke-dashoffset: 311;
  }
  44.44444444444444% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_15 {
  17.063492063492063% {
    stroke-dashoffset: 620;
  }
  44.841269841269835% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_16 {
  17.46031746031746% {
    stroke-dashoffset: 311;
  }
  45.238095238095234% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_17 {
  17.857142857142858% {
    stroke-dashoffset: 620;
  }
  45.63492063492063% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_18 {
  18.253968253968253% {
    stroke-dashoffset: 244;
  }
  46.03174603174603% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_19 {
  18.650793650793652% {
    stroke-dashoffset: 487;
  }
  46.42857142857143% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_20 {
  19.04761904761905% {
    stroke-dashoffset: 250;
  }
  46.82539682539682% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_21 {
  19.444444444444446% {
    stroke-dashoffset: 499;
  }
  47.22222222222222% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_22 {
  19.841269841269842% {
    stroke-dashoffset: 186;
  }
  47.61904761904761% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_23 {
  20.23809523809524% {
    stroke-dashoffset: 371;
  }
  48.01587301587302% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_24 {
  20.634920634920636% {
    stroke-dashoffset: 208;
  }
  48.41269841269841% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_25 {
  21.03174603174603% {
    stroke-dashoffset: 415;
  }
  48.80952380952381% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_26 {
  21.42857142857143% {
    stroke-dashoffset: 198;
  }
  49.20634920634921% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_27 {
  21.82539682539683% {
    stroke-dashoffset: 396;
  }
  49.60317460317461% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_28 {
  22.22222222222222% {
    stroke-dashoffset: 138;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_29 {
  22.61904761904762% {
    stroke-dashoffset: 274;
  }
  50.39682539682539% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_30 {
  23.015873015873016% {
    stroke-dashoffset: 175;
  }
  50.79365079365079% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_31 {
  23.41269841269841% {
    stroke-dashoffset: 349;
  }
  51.19047619047619% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_32 {
  23.809523809523807% {
    stroke-dashoffset: 139;
  }
  51.58730158730158% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_33 {
  24.206349206349206% {
    stroke-dashoffset: 279;
  }
  51.98412698412699% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_34 {
  24.603174603174605% {
    stroke-dashoffset: 111;
  }
  52.38095238095239% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes RiOWnJNP_draw_35 {
  25% {
    stroke-dashoffset: 223;
  }
  52.77777777777778% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
</style>
